import { getMessages } from '@/i18n/i18nHelper'
// !! Separate files are added automatically below, do not import them here.

const messages = {
  youAreLost: 'Jste ztraceni',
  youAreLostDesc: 'Tato stránka neexistuje',
  takeMeHome: 'Zpět na domácí obrazovku',
  home: 'Domů',
  global_search_placeholder: 'Nejdi článek podle ID / URL',
  create_new_article: 'Přidat nový článek',
  found: 'Nalezeno',
  result_not_found: 'Žádné záznamy',
  page_next: 'Další',
  page_prev: 'Předešlá',
  page_first: 'První',
  page_last: 'Poslední',
  page_go_to: 'Běž na stranu',
  page_record_number: 'Záznamů na stranu',
  actions: 'Akce',
  count: 'Počet',
  pick_some: 'Vybrat',
  enter_manually: 'Zadat ručně (enter)',
  type_keyword: 'Zde napište klíčové slovo',
  multiselect_no_result: 'Nebyly nalezeny žádné výsledky.',
  please_confirm: 'Prosím potvrďte (enter)',
  auto_refresh: 'Automatické obnovení obsahu',
  search: 'Hledat',
  reset: 'Resetovat',
  yes: 'Ano',
  no: 'Ne',
  and: 'a',
  reset_filter: 'Zrušit filtr',
  offline_state: 'Zdá se, že jste offline nebo máte dočasný výpadek internetu. Zkontrolujte prosím připojení k síti.',
  ctr: {
    total: 'Celkem',
    mobile: 'Mobil',
    desktop: 'Desktop'
  },
  system: {
    id: 'Id',
    created_at: 'Datum vytvoření',
    modified_at: 'Datum poslední změny',
    created_by: 'Vytvořil',
    modified_by: 'Změnil'
  },
  buttons: {
    save: 'Uložit',
    save_and_close: 'Uložit a zavřít',
    save_and_create_new_one: 'Uložit a vytvořit nový',
    save_draft: 'Uložit',
    create: 'Vytvořit nový',
    delete_button: 'Vymazat',
    usage_button: 'Použití',
    close: 'Zavřít',
    back: 'Zpět',
    preview: 'Náhled',
    edit: 'Upravit',
    info: 'Info',
    duplicate: 'Duplikovat',
    copy_id: 'Kopírovat ID',
    copy_url: 'Kopírovat URL adresu',
    set_to_now: 'Nastavit na teď',
    send_to_draft: 'Vrátit do konceptu',
    send_to_review: 'Ke kontrole',
    send_to_publish: 'Publikovat',
    edit_as_new_draft: 'Upravit jako nový koncept',
    unpublish: 'Odpublikovat',
    select_from_bank: 'Vybrat',
    upload_from_computer: 'Nahrát',
    upload_from_url: 'URL',
    upload_from_url_social: 'Vložit embed',
    upload_from_url_social_desc: '<strong>Vložte URL ve tvaru např.</strong> (https://www.instagram.com/p/CV_gh10sfqa <strong>nebo</strong> https://www.youtube.com/watch?v=obkrMiyDrbs)',
    upload_from_youtube: 'Nahrát z Youtube',
    confirm_and_upload: 'Potvrdit a nahrát',
    image_from_youtube: 'Dotáhnout obrázek z Youtube',
    media_edit_button: 'Upravit všechny fotky',
    select_photo_from_bank: 'Vybrat fotku',
    upload_photo_from_computer: 'Nahrát fotku',
    upload_photo_from_url: 'URL fotky',
    upload_photo_from_url_social: 'Embedovat fotku',
    set_address: 'Nastav adresu',
    reset_address: 'Zruš adresu',
    select: 'Vybrat',
    select_article: 'Vyber článek',
    select_articles: 'Vyber články',
    select_disease_article: 'Vyber onemocnění',
    select_disease_articles: 'Vyber onemocnění',
    select_quiz: 'Vyber kvíz',
    select_recipe: 'Vyber recept',
    select_test: 'Vyber test',
    select_print_publication: 'Vyber printové vydání',
    remove_print_publication: 'Odstraň printové vydání',
    select_ooayla: 'Vybrat ooyala video',
    select_video: 'Vybrat video',
    select_youtube: 'Vybrat Youtube',
    show_only_selected: 'Zobrazit pouze vybrané',
    search: 'Hledat',
    reset_filter: 'Zrušit filtr',
    reset_selection: 'Odznačit vybrané',
    bulk_actions: 'Hromadná úprava',
    load_more: 'Načíst více',
    load_more_items: 'Načíst další položky',
    content_block_settings: 'Content block nastavení',
    forward: 'Vpřed',
    backward: 'Dozadu',
    refresh: 'Aktualizovat',
    new_article: 'Nový článek'
  },
  modal: {
    media_search_header: 'Vybrat z banky',
    media_upload_header: 'Nahrát z PC',
    media_upload_header_2: 'Nahrát',
    media_url_header: 'Zadejte adresu URL, kterou chcete načíst',
    reading: 'Načítám...',
    uploading: 'Nahrávám...',
    uploading_done: 'Nahráno',
    done: 'Hotovo',
    title: 'Titulek',
    keywords: 'Keywords',
    author: 'Autor',
    authors: 'Autoři',
    show_author: 'Ukázat autora',
    hidden_caption_on_web: 'Nezobrazovat popis k foto na stránce',
    select: 'Vybrat',
    image_name: 'Název obrázku',
    image_url: 'Adresa URL obrázku',
    image_url_error_message: 'Na zadané URL adrese se nenachází žádný obrázek.',
    image_url_error_message_embed: 'Url musí být ve tvaru popsaném výše',
    pdf_url: 'Adresa URL dokumentu',
    pdf_url_error_message: 'Na zadané URL adrese se nenachází žádný dokuments.',
    search: 'Hledat',
    found: 'Nalezeno',
    filtr: 'Filtr',
    tags: 'Klíčová slova',
    embed_id: 'Id embedu',
    published_since: 'Publikováno od',
    published_until: 'Publikováno do',
    apply_filter: 'Aktualizovat filtr',
    reset: 'Reset',
    load_more: 'Načíst více',
    site: 'Stránka',
    fulltext: 'Fulltext',
    address_edit: 'Uprava adresy',
    id: 'Id',
    edit_images: 'Změň obrázky',
    delete_modal_header: 'Odstranit tento záznam?',
    delete_modal_text: 'Jste si jisti, že chcete odstranit tento záznam?',
    delete_modal_cancel_btn: 'Ne',
    delete_modal_confirm_btn: 'Ano, odstranit tento záznam',
    selected: 'Vybrané',
    usage_modal_header: 'Použití',
    usage_modal_description: 'Nemůžete vymazat tento záznam, dokud jej neodstraníte z následujících míst:',
    category: 'Kategorie',
    status: 'Status',
    published_count: 'Publikované',
    used_count: 'Počet použití',
    main_info: 'Hlavní informace',
    meta_info: 'Dodatečné informace',
    title_is_short: 'Titulek je příliš krátký',
    description: 'Popis',
    export_wood_wing: 'Exportovat do WoodWing',
    download_progress: 'Průběh stahování',
    created_at_from: 'Vytvořeno od',
    created_at_to: 'Vytvořeno do',
    create_gallery: 'Uložit galerii',
    cancel_create_gallery: 'Zrušit',
    insert: 'Vložit',
    public: 'Veřejná',
    instagram: {
      text1: 'Požadovaný Instagram post nelze vložit, pravděpodobně kvůli omezení na straně Instagramu.',
      text2: 'Post však lze vložit následujícím způsobem:',
      text3: '<b>Krok 1:</b> Zkopírujte tuto upravenou URL adresu postu:',
      text4: '<b>Krok 2:</b> Ve Vašem prohlížeči otevřete nový tab, vložte do něj tuto URL a stiskněte ENTER.',
      text5: '<b>Krok 3:</b> V tomto novém tabu se Vám zobrazí požadovaný obrázek a změněná URL adresa.',
      text6: 'Tuto novou URL adresu prosím zkopírujte a vložte do políčka níže pod tímto textem a stiskněte tlačítko Vložit.',
      cdnUrlPlaceholder: 'Zde vložte novou URL adresu...',
      cdnUrlError: 'Instagram tuto URL nerozpoznal. Zkuste znovu postup od kroku 1.',
      previewInfo: 'Pokud se Vám nezobrazuje náhled postu, zřejmě jste zadali špatnou URL adresu.'
    },
    hottopics: 'Vybrat Deník(y)'
  },
  notify: {
    id_was_copied: 'ID bylo zkopírováno do schránky',
    url_was_copied: 'URL byla zkopírována do schránky',
    please_fill_all_required_fields: 'Prosím vyplňte správně všechna povinná pole!',
    please_save_all_annotations: 'Prosím uložte všechny anotace!',
    record_was_created: 'Záznam byl vytvořen',
    record_was_updated: 'Záznam byl změněn',
    record_was_unpublished: 'Záznam byl odpublikován',
    records_were_updated: 'Záznamy byly změněny',
    record_was_deleted: 'Záznam byl smazán!',
    record_not_deleted: 'Záznam nebyl smazán!',
    record_in_use_cannot_be_deleted: 'Záznam nelze smazat, protože je používaný!',
    media_metadata_was_updated: 'Popisy obrázku byly změněny',
    extract_timeout_error: 'Nepodařilo se načíst všechny fotografie. Zkuste je nahrávat v menším počtu.',
    upload_timeout_error: 'Nepodařilo se nahrát všechny fotografie.',
    loading: 'Počkejte...',
    please_check_thumbnail_value: 'Prosím zkontrolujte hodnotu pro thumbnail'
  },
  media: {
    author: 'Autor',
    headline: 'Titulek',
    caption: 'Nadpis',
    description: 'Popis',
    keywords: 'Klíčová slova',
    created: 'Vytvořeno',
    actions: 'Akce',
    try_upload_again: 'Pokusit se nahrát nenahrané soubory znovu',
    try_again: 'Pokusit se znovu',
    failed_uploaded_files: 'Tyto soubory se nepodařilo nahrát:',
    metadata_info_header: 'Metadata info',
    button_edit_metadata: 'Upravit metadata',
    button_remove: 'Odstranit',
    button_add_media_to_gallery: 'Přidat do galerie',
    button_add_media_to_bodytext: 'Přesunout do těla článku',
    button_crop: 'Bod zájmu/ořez',
    button_download: 'Stáhnout original',
    button_download_zip: 'Stáhnout (.zip)',
    button_open_preview: 'Otevřít náhled',
    add_pagebreak_text: 'Zlom strany za každou fotkou',
    predetermined_values: 'Předurčené hodnoty',
    remove_all_titles: 'Vymazat všechny titulky',
    set_predetermined_titles: 'Nastav předurčené hodnoty',
    select_image: 'Vyberte obrázek',
    select_photo: 'Vyberte fotku',
    select_ooyala: 'Vyberte ooyala video',
    select_video: 'Vyberte video',
    set_unpublished: 'Nastavit vypnutí z webu',
    title_is_too_long: 'Titulek je příliš dlouhý. Při uložení bude oříznut na 500 znaků',
    main_image: 'Hlavní obrázek',
    main_video: 'Hlavní video'
  },
  author: {
    name: 'Jméno',
    originName: 'Původní jméno',
    slug: 'Slug',
    position: 'Pozice',
    description: 'Popis',
    email: 'Email',
    image: 'Obrázek',
    typ: 'Typ',
    disabled: 'Disabled',
    detailPage: 'Stránka detailu',
    podcast: 'Podcast',
    facebook: 'Facebook',
    google: 'Google',
    twitter: 'Twitter',
    instagram: 'Instagram',
    pinterest: 'Pinterest',
    flourish: 'Flourish',
    tableau: 'Tableau',
    infogram: 'Infogram'
  },
  version: {
    header_1: 'Vyšla nová verze EAGLE Admin.',
    header_2: 'Uložte si práci a načtěte novou verzi.',
    current_version: 'Aktuální verze',
    available_version: 'Nová verze',
    load_new_version: 'Načíst novou verzi'
  },
  source: 'Zdroj'
}

const files = require.context(
  '.', // current directory
  true, // subdirectories
  /.+\.js$/ // only .js
)

const allMessages = getMessages(files, messages)
export default allMessages
