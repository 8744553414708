import { dateTimeNow } from '@/services/DateTimeService'

export default {
  id: 0,
  entityUuid: '',
  title: '',
  text: '',
  linkUrl: '',
  siteIds: [],
  publishDateFrom: dateTimeNow(),
  publishDateTo: dateTimeNow(),
  createdAt: dateTimeNow(),
  modifiedAt: dateTimeNow(),
  createdBy: 0,
  modifiedBy: 0,
  siteId: 0
}
