export const BREAKING_NEWS_STATE_PUBLISHED = 'Active'
export const BREAKING_NEWS_STATE_UNPUBLISHED = 'Inactive'

export const BREAKING_NEWS_TYPE_DEFAULT = BREAKING_NEWS_STATE_PUBLISHED

export default {
  computed: {
    breakingNewsStateValues () {
      return [
        {
          id: BREAKING_NEWS_STATE_PUBLISHED,
          title: 'Aktivní'
          // title: this.$t('contentBlock.content_block_type_value.article')
        },
        {
          id: BREAKING_NEWS_STATE_UNPUBLISHED,
          title: 'Neaktivní'
          // title: this.$t('contentBlock.content_block_type_value.video')
        }
      ]
    }
  }
}
