const messages = {
  list: {
    id: 'ID',
    message: 'Message',
    site: 'Journal',
    from: 'From',
    to: 'To',
    published: 'Published'
  },
  form: {
    title_label: 'Title',
    title_placeholder: 'Title of the message'
  },
  inactive: 'Inactive',
  active: 'Active'
}

export default messages
