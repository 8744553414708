import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  // TODO: this is for test
  // PermissionService.REQUIRED_PERMISSIONS.GALLERY_PERMISSIONS
  // TODO: USE THIS ONCE WE HAVE CREATED SSO FOR "hottopics" MODULE
  PermissionService.REQUIRED_PERMISSIONS.HOT_TOPICS_PERMISSIONS

const routes = [
  {
    path: 'hottopics/new',
    component: () => import('../../views/hottopics/HotTopicsNewView'),
    name: 'hottopics_new',
    meta: {
      description: 'HotTopics new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'hottopics/:id/edit',
    component: () => import('../../views/hottopics/HotTopicsEditView'),
    name: 'hottopics_edit',
    meta: {
      description: 'HotTopics edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'hottopics',
    component: () => import('../../views/hottopics/HotTopicsListView'),
    name: 'hottopics_list',
    meta: {
      description: 'HotTopics list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
