const messages = {
  list: {
    id: 'ID',
    message: 'Správa',
    site: 'Denník',
    from: 'Od',
    to: 'Do',
    published: 'Publikované'
  },
  form: {
    title_label: 'Titulok',
    title_placeholder: 'Titulok správy'
  },
  inactive: 'Neaktívne',
  active: 'Aktívne'
}

export default messages
