const messages = {
  list: {
    id: 'ID',
    message: 'Zpráva',
    site: 'Deník',
    from: 'Od',
    to: 'Do',
    published: 'Publikováno'
  },
  form: {
    title_label: 'Titulek',
    title_placeholder: 'Titulek zprávy'
  },
  inactive: 'Neaktivní',
  active: 'Aktivní'
}

export default messages
