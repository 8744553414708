const messages = {
  text: 'Text',
  text_placeholder: 'Napište nadpis infoboxu',
  link: 'Odkaz',
  link_placeholder: 'Odkaz na stránku (https://...)',
  panel_positions: 'Pozice panelu',
  created_date: 'Datum vytvoření',
  changed_date: 'Datum změny',
  sites_label: 'Publikovat na Deníky (přepíše aktuálně publikované panely na dané pozici)',
  image_label: 'Obrázek panelu',
  list: {
    id: 'ID',
    text: 'Text',
    position: 'Pozice',
    from: 'Od',
    to: 'Do',
    state: 'Stav'
  },
  create_panel_label: 'Vytvořit panel',
  switch: {
    graphics_label: 'Grafické',
    text_label: 'Textové'
  }
}

export default messages
