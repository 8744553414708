import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

import user from './modules/userStore'
import article from './modules/articleStore'
import rubric from './modules/rubricStore'
import author from './modules/authorStore'
import source from './modules/sourceStore'
import otherFunction from './modules/otherFunctionStore'
import tag from './modules/tagStore'
import city from './modules/cityStore'
import cuisineCategory from './modules/cuisineCategoryStore'
import region from './modules/regionStore'
import district from './modules/districtStore'
import address from './modules/addressStore'
import site from './modules/siteStore'
import safetyTopic from './modules/safetyTopicStore'
import media from './modules/mediaStore'
import infobox from './modules/infoboxStore'
import joke from './modules/jokeStore'
import quote from './modules/quoteStore'
import quiz from './modules/quizStore'
import quizVlm from './modules/quizStoreVlm'
import articleTest from './modules/articleTestStore'
import car from './modules/carStore'
import restaurant from './modules/restaurantStore'
import hotel from './modules/hotelStore'
import contentBlock from './modules/contentBlockStore'
import pollBlock from './modules/pollBlockStore'
import contentBlockItem from './modules/contentBlockItemStore'
import category from './modules/categoryStore'
import poll from './modules/pollStore'
import pollVlm from './modules/pollStoreVlm'
import dam from './modules/damStore'
import nativeCampaignManagement from './modules/donBox/nativeCampaignManagementStore'
import nativePerfArticle from './modules/don/nativePerfArticleStore'
import nativePerfConfig from './modules/don/nativePerfConfigStore'
import nativePerfPredictions from './modules/don/nativePerfPredictionsStore'
import nativePerfAnalytics from './modules/don/nativePerfAnalyticsStore'
import activity from './modules/activityStore'
import beUser from './modules/beUser/beUserStore'
import beUserKpi from './modules/beUser/beUserKpiStore'
import kpiComparison from './modules/kpiComparisonStore'
import kpiSummary from './modules/kpiSummaryStore'
import edonProduct from './modules/edonProductStore'
import feUser from './modules/feUser/feUserStore'
import feUserDeliveryAddress from './modules/feUser/feUserDeliveryAddressStore'
import feUserBillingAddress from './modules/feUser/feUserBillingAddressStore'
import aboCountry from './modules/feUser/aboCountryStore'
import feUserArticle from './modules/feUser/feUserArticleStore'
import feUserPollVote from './modules/feUser/feUserPollVoteStore'
import redirect from './modules/redirectStore'
import redirectVlm from './modules/redirectStoreVlm'
import feature from './modules/featureStore'
import articleHistory from './modules/articleHistoryStore'
import sport24season from './modules/sportSeasonStore'
import sport24teamSeason from './modules/sportTeamSeasonStore'
import tasrNews from './modules/tasrNewsStore'
import tasrNewsCategory from './modules/tasrNewsCategoryStore'
import printTitle from './modules/printTitleStore'
import printPublication from './modules/printPublicationStore'
import video from './modules/videoStore'
import videoAnnotation from './modules/videoAnnotationStore'
import videoShow from './modules/videoShowStore'
import videoCategory from './modules/videoCategoryStore'
import printArticle from './modules/printArticleStore'
import department from './modules/departmentStore'
import appPerformance from './modules/appPerformanceStore'
import scale from './modules/scaleStore'
import topic from './modules/topicStore'
import eshopSubscriptionProduct from './modules/eshop/subscriptionProductStore'
import eshopSubscriptionOrder from './modules/eshop/subscriptionOrderStore'
import eshopAboProduct from './modules/eshop/aboProductStore'
import eshopAboEdition from './modules/eshop/aboEditionStore'
import eshopAboPeriodikPrice from './modules/eshop/aboPeriodikPriceStore'
import eshopHistory from './modules/eshop/historyStore'
import eshopSubscriptionPayment from './modules/eshop/subscriptionPaymentStore'
import eshopSubscriber from './modules/eshop/subscriberStore'
import eshopSubscription from './modules/eshop/subscriptionStore'
import eshopDigitalService from './modules/eshop/digitalServiceStore'
import eshopFeature from './modules/eshop/featureStore'
import eshopDashboard from './modules/eshop/dashboardStore'
import eshopTopProductSetting from './modules/eshop/topProductSettingStore'
import editorialPerformance from './modules/editorialPerformanceStore'
import coefficients from './modules/coefficientsStore'
import weights from './modules/weightsStore'
import kpi from './modules/kpiStore'
import recipe from './modules/recipeStore'
import recipeCategory from './modules/recipeCategoryStore'
import hpTopicOffer from './modules/hpTopicOfferStore'
import woodWing from './modules/woodWingStore'
import cluster from './modules/clusterStore'
import historicalPerformance from './modules/historicalPerformanceStore'
import kpiNmh from './modules/kpiNmhStore'
import kpiNmhDataHub from './modules/kpiNmhDataHubStore'
import realtimeStatistics from './modules/realtimeStatisticsStore'
import historicalDashboard from './modules/historicalDashboardStore'
import notificationService from './modules/notificationServiceStore'
import nmhEditorStore from './modules/nmhEditorStore'
import dash from './modules/dashStore'
import aiStore from './modules/aiStore'
import gallery from './modules/galleryStore'
import brand from './modules/userManagement/brandStore'
import capability from './modules/userManagement/capabilityStore'
import group from './modules/userManagement/groupStore'
import module from './modules/userManagement/moduleStore'
import hottopics from './modules/hottopicsStore'
import breakingnews from './modules/breakingNewsStore'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules: {
    user,
    article,
    rubric,
    author,
    source,
    otherFunction,
    tag,
    city,
    cuisineCategory,
    region,
    district,
    address,
    site,
    safetyTopic,
    media,
    infobox,
    joke,
    quote,
    quiz,
    quizVlm,
    articleTest,
    car,
    restaurant,
    hotel,
    contentBlock,
    pollBlock,
    contentBlockItem,
    category,
    poll,
    pollVlm,
    dam,
    nativeCampaignManagement,
    nativePerfArticle,
    nativePerfConfig,
    nativePerfPredictions,
    nativePerfAnalytics,
    activity,
    beUser,
    beUserKpi,
    kpiComparison,
    kpiSummary,
    edonProduct,
    feUser,
    feUserDeliveryAddress,
    feUserBillingAddress,
    aboCountry,
    feUserArticle,
    feUserPollVote,
    redirect,
    redirectVlm,
    feature,
    articleHistory,
    sport24season,
    sport24teamSeason,
    tasrNews,
    tasrNewsCategory,
    printTitle,
    printPublication,
    video,
    videoAnnotation,
    videoShow,
    videoCategory,
    printArticle,
    department,
    appPerformance,
    scale,
    topic,
    eshopSubscriptionProduct,
    eshopSubscriptionOrder,
    eshopAboProduct,
    eshopAboEdition,
    eshopAboPeriodikPrice,
    eshopHistory,
    eshopSubscriptionPayment,
    eshopSubscriber,
    eshopSubscription,
    eshopDigitalService,
    eshopFeature,
    eshopDashboard,
    eshopTopProductSetting,
    editorialPerformance,
    coefficients,
    weights,
    kpi,
    recipe,
    recipeCategory,
    hpTopicOffer,
    woodWing,
    cluster,
    historicalPerformance,
    kpiNmh,
    kpiNmhDataHub,
    realtimeStatistics,
    historicalDashboard,
    gallery,
    notificationService,
    dash,
    nmhEditorStore,
    aiStore,
    brand,
    capability,
    group,
    module,
    hottopics,
    breakingnews
  }
})
