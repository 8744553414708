import CoreApi from '@/api/core'
import HottopicsModel from '@/model/HottopicsModel'

const API_NAME = '/hottopic'

const state = {
  error: null,
  list: [],
  totalCount: 0,
  page: 1,
  filter: {
    id: '',
    title: ''
  },
  // hottopics
  detail: HottopicsModel,
  board: [],
  hottopicImage: null,
  siteId: null
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  setPage (state, page) {
    state.page = page
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  resetFilter (state) {
    state.filter = {
      id: '',
      title: ''
    }
  },
  // hottopics
  storeHottopicImage (state, hottopicImage) {
    state.hottopicImage = hottopicImage
  },
  storeBoard (state, responseData) {
    state.board = responseData
  },
  setError (state, message) {
    state.error = message
  },
  setSiteId (state, siteId) {
    state.siteId = siteId
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  }
}

const actions = {
  async fetchImage (store, id) {
    await CoreApi().get('/image/' + id)
      .then(response => {
        store.commit('storeHottopicImage', response.data)
      })
      .catch(error => console.log(error))
  },
  async fetchOne (store, id) {
    await CoreApi().get(API_NAME + '/items/' + id)
      .then(response => {
        store.commit('storeHottopicImage', null)
        store.commit('storeDetail', response.data)
        if (response.data.imageId) {
          store.dispatch('fetchImage', response.data.image)
        }
      })
      .catch(error => console.log(error))
  },
  async fetchBoard (store, id) {
    await CoreApi().get(API_NAME + '/boards/' + id)
      .then(response => {
        store.commit('storeBoard', response.data)
        store.commit('setSiteId', id)
      })
      .catch(error => console.log(error))
  },
  async create (store, record) {
    return await CoreApi().post(API_NAME, JSON.stringify(record))
      .then(response => {
        if (response.status === 201 || response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async update (store, record) {
    return await CoreApi().put(API_NAME + '/items/' + record.id, JSON.stringify(record))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
          return response.data
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async deleteRecord (store, record) {
    return await CoreApi().delete(API_NAME + '/items/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async unpublish (store, record) {
    return await CoreApi().put(API_NAME + '/items/unpublish/' + record)
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async updateGraphicsTextSwitch (store, { id, textOnly }) {
    return await CoreApi().put(API_NAME + '/boards/' + id, JSON.stringify({ id, textOnly }))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeBoard', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  }
}

const getters = {
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  filter (state) {
    return state.filter
  },
  // hottopics
  hottopicImage (state) {
    return state.hottopicImage
  },
  board (state) {
    return state.board
  },
  error (state) {
    return state.error
  },
  siteId (state) {
    return state.siteId
  },
  detail (state) {
    return state.detail
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
